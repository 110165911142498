import { React, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import "./assets/css/main.css";
import Navbar from "./components/navbar";
import Hero from "./components/hero";
import About from "./components/about";
import Parallax from "./components/parallax";
import Skills from "./components/skills";
import Portfolio from "./components/portfolio";
import Project from "./components/project";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Content from "./assets/js/content";

const Wrapper = styled.div`
  min-height: 100vh;
  padding-bottom: 56px;
  position: relative;
  overflow: hidden;
`;

function App() {
  const language = useState("EN");
  const [content, setContent] = useState(Content[language[0]]);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <Wrapper className="app">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Main
                content={content}
                setContent={setContent}
                language={language}
              />
            }
          />
          <Route path="/:projectName" element={<Project content={content} />} />
          <Route
            path="*"
            element={
              <Main
                content={content}
                setContent={setContent}
                language={language}
              />
            }
          />
        </Routes>
      </BrowserRouter>
      <Footer />
    </Wrapper>
  );
}

function Main({ content, setContent, language }) {
  return (
    <div className="Main">
      <Navbar
        content={content}
        setContent={setContent}
        language={language}
        Content={Content}
      />
      <Hero id={0} content={content} />
      <About id={1} content={content} />
      <Parallax id={2} content={content} />
      <Skills id={3} content={content} />
      <Portfolio id={4} content={content} />
      <Contact id={5} content={content} />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Flags from "country-flag-icons/react/3x2";
import { Fragment } from "react";

const Brand = styled.p`
  color: #fff;
  margin: 13px;
  font-size: 28px;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
`;

const NavItem = styled.p`
  color: #fff;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  text-decoration: underline 0.15em rgba(255, 255, 255, 0);
  transition: text-decoration-color 0.4s;
  cursor: pointer;
  &:hover {
    color: #fff;
    text-decoration-color: rgba(255, 255, 255, 1);
  }
`;

const Navigation = ({ content, setContent, language, Content }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [lang, setLang] = language;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    setContent(Content[lang]);
  }, [lang, Content, setContent]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const flags = {
    EN: <Flags.US style={{ width: "40px" }} />,
    FR: <Flags.FR style={{ width: "40px" }} />,
    // DE: <Flags.DE />,
  };

  const { navItems } = content;

  return (
    <Navbar
      expand="lg"
      fixed="top"
      variant="dark"
      className={`${scrollPosition === 0 ? "bg-transp" : "bg-dark"}`}
    >
      <Container fluid>
        <Brand as={Link} to="home">
          Weberdevelop
        </Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="ms-auto">
            {navItems.map((item, index) => (
              <NavItem
                key={item}
                as={Link}
                to={`${index}`}
                className="nav-link text-white"
                offset={-83}
              >
                {item}
              </NavItem>
            ))}
            {Object.keys(flags).map((flag) => (
              <Fragment key={flag}>
                {flag !== lang && (
                  <button
                    className="btn"
                    style={{ width: "70px" }}
                    onClick={() => setLang(flag)}
                  >
                    {flags[lang]}
                  </button>
                )}
              </Fragment>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;

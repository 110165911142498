import React from "react";
import styled from "styled-components";
import ContactBg from "../assets/img/contact-bg.jpg";
import { AiOutlineLinkedin, AiOutlineGithub } from "react-icons/ai";

const Socials = [
  {
    name: "linkedin",
    icon: <AiOutlineLinkedin />,
    url: "https://www.linkedin.com/in/jweber11/",
  },
  {
    name: "github",
    icon: <AiOutlineGithub />,
    url: "https://github.com/eggplanthero",
  },
];

const Jumbo = styled.div`
  width: 100%;
  height: 40vh;
  background-image: url(${ContactBg});
  background-size: cover;
  background-attachment: fixed;
  background-position: 65% 50%;
  position: relative;
`;

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  display: inline-block;
`;

const Social = styled.a`
  font-size: 2.5rem;
  text-decoration: none;
`;

const Contact = ({ content, id }) => {
  const { contact } = content;
  return (
    <Jumbo id={id}>
      <div className="center-vertical">
        <Container className="p-4" data-aos="zoom-out-up">
          {contact.map(({ name, data, icon }) => (
            <p key={name} className="text-white">
              {icon} {data}
            </p>
          ))}
          {Socials.map(({ name, icon, url }) => (
            <Social key={name} href={url} className="text-white mx-1">
              <span>{icon}</span>
            </Social>
          ))}
        </Container>
      </div>
    </Jumbo>
  );
};

export default Contact;

import React from "react";
import styled from "styled-components";
import ParallaxBg from "../assets/img/parallax-bg.jpg";
import { FaDownload } from "react-icons/fa";

const Jumbo = styled.div`
  width: 100%;
  height: 30vh;
  background-image: url(${ParallaxBg});
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  position: relative;
`;

const Parallax = ({ content, id }) => {
  const { download, resume } = content;
  return (
    <Jumbo id={id}>
      <div className="center-vertical">
        <a href={resume}>
          <button className="btn btn-danger btn-lg">
            <FaDownload /> {download}
          </button>
        </a>
      </div>
    </Jumbo>
  );
};

export default Parallax;

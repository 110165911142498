import React from "react";
import Avatar from "../assets/img/avatar.jpg";
import styled from "styled-components";

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

const About = ({ content, id }) => {
  const { title, description, technologies } = content.about;
  return (
    <section id={id}>
      <div className="container">
        <h1 className="title" data-aos="zoom-out-up">
          {title}
        </h1>

        <div className="box-shadow">
          <div className="row">
            {/* LEFT SIDE */}
            <div className="col-sm-6">
              {/* avatar */}
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="text-center" data-aos="zoom-in-up">
                    <div className="img-fluid img-thumbnail">
                      <Image src={Avatar} alt="" />
                      <p className="text-center my-0">Jesse Weber</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* skills  */}
              <div className="row my-3" data-aos="zoom-in-up">
                {technologies.map(({ name, width, color }) => (
                  <div key={name} className="d-inline-block col-md-6 px-3">
                    <span>{name}</span>
                    <div className="progress">
                      <div
                        className={`progress-bar bg-${color}`}
                        style={{ width: width }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* RIGHT SIDE */}
            <div className="col-sm-6" data-aos="zoom-in-up">
              {description.map((p, i) => (
                <p className="lead" key={i}>
                  {p}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  color: #212529;
  text-decoration: none;

  &:hover {
    color: #212529;
  }
`;

const Portfolio = ({ content, id }) => {
  const { title, projects } = content.portfolio;
  return (
    <section id={id}>
      <div className="container">
        <h3 className="title" data-aos="zoom-out-up">
          {title}
        </h3>
        <div className="row">
          {projects.map(({ name, thumbnail, link, category, date }) => (
            <div className="col-md-4" key={name} data-aos="zoom-in-up">
              <StyledLink to={`/${link}`}>
                <div className="box-shadow">
                  <div className="zoom-frame">
                    <img src={thumbnail} alt={name} className="img-fluid" />
                  </div>
                  <div>
                    <h2>{name}</h2>
                    <p>
                      <span className="text-primary">{category}</span> / {date}
                    </p>
                  </div>
                </div>
              </StyledLink>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;

import React from "react";
import styled from "styled-components";

const Foot = styled.footer`
  background-color: #0062d3;
  color: #fff;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0;
`;

const Footer = () => {
  return (
    <Foot>
      <p className="m-auto py-3">
        Copyright &copy; {new Date().getFullYear()}{" "}
        <strong>Weberdevelop</strong>.
      </p>
    </Foot>
  );
};

export default Footer;

import { ImMobile2 } from "react-icons/im";
import { AiFillPhone, AiOutlineMail } from "react-icons/ai";
import { BiGitBranch } from "react-icons/bi";
import { SiJest } from "react-icons/si";
import { IoLogoGameControllerB } from "react-icons/io";
import { BsFillCloudArrowUpFill, BsCodeSlash } from "react-icons/bs";
import ResumeEN from "../img/Resume-EN.pdf";
import ResumeFR from "../img/Resume-FR.pdf";
import imgbracer from "../img/bracer.png";
import imgsweetscent from "../img/sweetscent.png";
import imgwizardman from "../img/wizardman.png";
import imgspellbook from "../img/spellbook.png";
import imgsalonclouds from "../img/salonclouds.png";

const content = {
  EN: {
    language: "EN",
    navItems: ["home", "about", "resume", "skills", "work", "contact"],
    about: {
      title: "About Me",
      description: [
        "Hello! My name is Jesse and I like to create small apps for fun, such as tools that help people plan out a game strategy, or keep track of statistics.",
        "That's why I learned web development! It's important to me that users are able to use a UI with the least amount of effort possible.",
        "My interest in coding started back in 2010 when I decided to try modifying video game source code. But at the same time, I was learning to play the guitar. Torn between the ambitions of becoming a developer or a musician, I decided to do both! I'll create my own video games, and compose the soundtrack for them!",
        "Five years ago, I enrolled at Codify Academy, a small coding bootcamp in the heart of San Francisco, which introduced me to HTML and CSS. Ever since graduation, I'm completely self-taught, and haven't stopped evolving.",
        "Fast-forward to today, as a specialist of React/Redux framework for front-end, and Node/Express for back-end, I'm able to make fullstack applications from the ground up, with beautiful, intuitive user interfaces.",
      ],
      technologies: [
        { name: "English", width: "100%", color: "info" },
        { name: "French", width: "100%", color: "info" },
        { name: "German", width: "75%", color: "info" },
        { name: "Japanese", width: "50%", color: "info" },
        { name: "HTML", width: "90%", color: "primary" },
        { name: "CSS", width: "90%", color: "primary" },
        { name: "Bootstrap", width: "100%", color: "primary" },
        { name: "Javascript", width: "95%", color: "primary" },
        { name: "React", width: "100%", color: "primary" },
        { name: "Redux", width: "100%", color: "primary" },
        { name: "C#", width: "90%", color: "primary" },
        { name: "Git", width: "75%", color: "primary" },
        { name: "Node", width: "70%", color: "primary" },
        { name: "Express", width: "70%", color: "primary" },
      ],
    },
    download: "Download Resume",
    resume: ResumeEN,
    skills: {
      title: "Skills",
      technologies: [
        {
          name: "Web Development",
          icon: <BsCodeSlash />,
          description:
            "Specialist in building user-friendly, data-rich web applications, notably via React/Redux framework.",
        },
        {
          name: "Responsive Design",
          icon: <ImMobile2 />,
          description:
            "Web pages designed to render beautifully on all devices, with the help of libraries like Bootstrap.",
        },
        {
          name: "REST APIs",
          icon: <BsFillCloudArrowUpFill />,
          description:
            "Proficient in consuming APIs, as well as creating them through via MERN Stack.",
        },
        {
          name: "Version Control",
          icon: <BiGitBranch />,
          description:
            "Work in collaboration with other developers via Github.",
        },
        {
          name: "Unit Testing",
          icon: <SiJest />,
          description:
            "Ensure correctness of code by writing accurate tests using Jest.",
        },
        {
          name: "Game Development",
          icon: <IoLogoGameControllerB />,
          description:
            "Ability to code and architect gameplay mechanics and tools using Unity Game Engine and C#.",
        },
      ],
    },
    portfolio: {
      title: "portfolio",
      projects: [
        {
          name: "Bracer",
          thumbnail: imgbracer,
          link: "bracer",
          url: "https://bracer.app/",
          github: [
            {
              tag: "Front-End",
              url: "https://github.com/EggplantHero/Bracer-frontend",
            },
            {
              tag: "Back-End",
              url: "https://github.com/wcshamblin/Bracer-Algorithm",
            },
          ],
          category: "React, Front-End",
          date: "April 2022",
          description: [
            "Bracer is an app that helps competitive pokemon players plan out a roadmap for how to create very powerful pokemon. This app is specifically for the 'PokeMMO' version of the game.",
            "Similar to many RPGs, gear can be refined by using weaker gear as materials. In PokeMMO, it can require putting together up to 64 different pokemon just to obtain one strong pokemon, and the exact order in which to put them together can be very complex to calculate -- hence the need for this app.",
            "It allows the user to enter any amount of gear that they own, along with their stats, and receive in return a roadmap in the form of a family tree. This tree is the cheapest combination to obtain the desired refined gear.",
            "My role in this project was to design the logic of the algorithm that makes these calculations, which is then written by my colleague in Python. I also built the front-end with React and Redux, to get the user's input, send it to the python app via REST API, receive the tree, and finally display that tree on the page.",
          ],
        },
        {
          name: "Sweetscent",
          thumbnail: imgsweetscent,
          link: "sweetscent",
          url: "https://www.sweetscent.net/",
          github: [
            {
              tag: "Github",
              url: "https://github.com/EggplantHero/Sweetscent",
            },
          ],
          category: "React, Front-End",
          date: "June 2021",
          description: [
            "Sweet Scent is an app that helps competitive pokemon players keep track of statistics, notably of how many times they have seen any particular pokemon.",
            "Some phenomenon can only occur once every 8000 encounters, so it's important for people to keep track of how many encounters they've willfully triggered in hopes of seeing this phenomenon.",
            "This app was made with the intent of learning React/Redux, and so it is my first completed app using these technologies. It's a more elaborate variant of the classic 'counter button app', subject of many tutorials online.",
          ],
        },
        {
          name: "Wizardman",
          thumbnail: imgwizardman,
          link: "wizardman",
          url: "",
          github: [
            {
              tag: "Github",
              url: "https://github.com/EggplantHero/Wizardman",
            },
          ],
          category: "Game Development",
          date: "April 2022",
          description: [
            "Wizardman is a 2D platformer video game, built with C# and Unity, intended for release on mobile devices.",
            "While not directly related to front-end development, this project has given me the opportunity to master more advances notions like class inheritance, lifecycle hooks, virtual keyword, finite state machines etc, and generally expand my understanding of programming.",
            "Wizardman is slated for release within 2022.",
          ],
        },
        {
          name: "5e Spellbook",
          thumbnail: imgspellbook,
          link: "5e-spellbook",
          url: "https://5e-spellbook.netlify.app/",
          github: [
            {
              tag: "Front End",
              url: "https://github.com/EggplantHero/spellbook-react",
            },
            {
              tag: "Back End",
              url: "https://github.com/EggplantHero/spellbook-node",
            },
          ],
          category: "MERN Fullstack",
          date: "August 2020",
          description: [
            "5e Spellbook is a tool that allows a user to keep track of magic spells known by their character in the role-playing game 'Dungeons & Dragons (5th Edition)'.",
            "The intent of this app was to practice API design via node.js and express, full with account creation, login, security features like auth tokens, password hashing, JSON web tokens, etc.",
            "On the front-end, this app allowed me to learn how to get data via REST API, display it on the page, and finally sort and filter it depending on user's preferences.",
          ],
        },
        {
          name: "SalonClouds+",
          thumbnail: imgsalonclouds,
          link: "salonclouds",
          url: "https://saloncloudsplus.com/portfolio-websites",
          github: [],
          category: "HTML, CSS",
          date: "December 2021",
          description: [
            "SalonClouds+ is a company that creates custom mobile applications and websites for salons, and such have a whole portfolio of developed websites of their own.",
            "While I was employed as a tech support agent, I have been asked many times to modify content or apply layout fixes to many of their websites.",
            "Some of my code is scattered throughout all the websites on their portfolio, including the company's website itself.",
          ],
        },
      ],
    },
    contact: [
      { name: "Name", data: "Jesse Weber", icon: false },
      { name: "Profile", data: "Front-End Developer", icon: false },
      {
        name: "Email",
        data: "jessetristanweber@gmail.com",
        icon: <AiOutlineMail />,
      },
      { name: "Phone", data: "+33 7 69 71 42 16", icon: <AiFillPhone /> },
    ],
  },

  FR: {
    language: "FR",
    navItems: [
      "accueil",
      "à propos",
      "CV",
      "compétences",
      "projets",
      "contact",
    ],
    about: {
      title: "À Propos de Moi",
      description: [
        "Bonjour! Je m'appelle Jesse et j'aime créer des petites applications utilitaires, telles que des outils qui aident à planifier une stratégie de jeu, ou suivre des statistiques.",
        "C'est pourquoi j'ai appris le développement web! Il est important pour moi que mes utilisateurs puissent se servir d'un interface avec le moins d'effort possible.",
        "Mon intérêt pour le développement a débuté en 2010 quand j'avais tenté de modifier du code de jeu vidéo. Mais en même temps, j'apprenais aussi la guitare. Partagé entre les ambitions de devenir développeur ou musicien, j'ai décidé de faire les deux! Je créérai mes propres jeux vidéo, et je leur composerai aussi la bande son!",
        "Aujourd'hui, spécialiste de la framework React/Redux en front-end, et Node/Express en back-end, je suis capable de concevoir et réaliser des applications full-stack à partir de zéro avec des interfaces belles et intuitives.",
      ],
      technologies: [
        { name: "Anglais", width: "100%", color: "info" },
        { name: "Français", width: "100%", color: "info" },
        { name: "Allemand", width: "75%", color: "info" },
        { name: "Japonais", width: "50%", color: "info" },
        { name: "HTML", width: "90%", color: "primary" },
        { name: "CSS", width: "90%", color: "primary" },
        { name: "Bootstrap", width: "100%", color: "primary" },
        { name: "Javascript", width: "95%", color: "primary" },
        { name: "React", width: "100%", color: "primary" },
        { name: "Redux", width: "100%", color: "primary" },
        { name: "C#", width: "90%", color: "primary" },
        { name: "Git", width: "75%", color: "primary" },
        { name: "Node", width: "70%", color: "primary" },
        { name: "Express", width: "70%", color: "primary" },
      ],
    },
    download: "Télécharger CV",
    resume: ResumeFR,
    skills: {
      title: "Compétences",
      technologies: [
        {
          name: "Développement Web",
          icon: <BsCodeSlash />,
          description:
            "Spécialiste en réalisation d'applications conviviales et riches en données, notamment via la framework React/Redux.",
        },
        {
          name: "Design Réactif",
          icon: <ImMobile2 />,
          description:
            "Pages web conçues pour rendre parfaitement sur tous appareils, avec l'aide de bibliothèques comme Bootstrap.",
        },
        {
          name: "REST APIs",
          icon: <BsFillCloudArrowUpFill />,
          description:
            "Compétent en consommation d'APIs, aussi bien qu'en leur création via le MERN Stack.",
        },
        {
          name: "Contrôle de version",
          icon: <BiGitBranch />,
          description:
            "Travail en collaboration avec d'autres developers grâce à Github.",
        },
        {
          name: "Tests Unitaires",
          icon: <SiJest />,
          description:
            "Garantir la qualité du code en rédigeant des tests précis avec Jest.",
        },
        {
          name: "Dévelopment Jeu Vidéo",
          icon: <IoLogoGameControllerB />,
          description:
            "Capacité à coder et architecturer des mécaniques de gameplay et des outils en utilisant Unity Game Engine et C#.",
        },
      ],
    },
    portfolio: {
      title: "portfolio",
      projects: [
        {
          name: "Bracer",
          thumbnail: imgbracer,
          link: "bracer",
          url: "https://bracer.app/",
          github: [
            {
              tag: "Front-End",
              url: "https://github.com/EggplantHero/Bracer-frontend",
            },
            {
              tag: "Back-End",
              url: "https://github.com/wcshamblin/Bracer-Algorithm",
            },
          ],
          category: "React, Front-End",
          date: "April 2022",
          description: [
            "Bracer est une application qui permet aux joueurs compétitifs de Pokémon de planifier une feuille de route pour créer des Pokémon très puissants. Cette application est spécifiquement conçue pour la version 'PokeMMO' du jeu.",
            "Similaire à d'autres RPGs, l'équipement peut etre raffiné en consommant de l'équipement plus faible comme matériau. Dans PokeMMO, il peut nécessiter jusqu'à 64 pokémon différents à mettre ensemble pour en créer un seul qui est fort, et l'ordre exact dans lequel les assembler peut être très compliqué à calculer -- d'où l'intérêt de cette application.",
            "Elle permet à l'utilisateur d'entrer toute quantité d'équipement qu'il possède, ainsi que leurs caractéristiques, et recevoir en retour une feuille de route dans la forme d'un arbre généalogique. Cet arbre est la combinaison la moins chère pour obtenir l'équipement raffiné désiré.",
            "Mon rôle dans ce projet était de concevoir la logique de l'algorithme qui effectue ces calculs, qui ont été programmés par mon collègue en Python. J'ai aussi construit le front-end avec React et Redux pour obtenir la saisie de l'utilisateur, l'envoyer par REST API à l'application Python, recevoir l'arbre, et finalement l'afficher' sur la page.",
          ],
        },
        {
          name: "Sweetscent",
          thumbnail: imgsweetscent,
          link: "sweetscent",
          url: "https://www.sweetscent.net/",
          github: [
            {
              tag: "Github",
              url: "https://github.com/EggplantHero/Sweetscent",
            },
          ],
          category: "React, Front-End",
          date: "June 2021",
          description: [
            "Sweet Scent est une application qui permet aux joueurs compétitifs de Pokémon de suivre des statistiques, notamment de combien de fois ils ont rencontré n'importe quel Pokémon.",
            "Il y a des phénomènes dans le jeu qui peuvent avoir lieu une fois sur 8000 rencontres, donc il est important pour les joueurs de savoir combien de rencontres ils ont déclenché.",
            "Cette application a été créée avec l'intention d'apprendre React et Redux, il s'agit donc de ma première application complétée en utilisant ces technologies. C'est une variante de la classique application 'bouton compteur', sujet de nombreux tutoriels en ligne.",
          ],
        },
        {
          name: "Wizardman",
          thumbnail: imgwizardman,
          link: "wizardman",
          url: "",
          github: [
            {
              tag: "Github",
              url: "https://github.com/EggplantHero/Wizardman",
            },
          ],
          category: "Game Development",
          date: "April 2022",
          description: [
            "Wizardman est un jeu vidéo 2D platformer, construit avec C# et Unity Game Engine, prévu pour publication sur appareils mobiles.",
            "Bien qu'il n'y ait pas de rapport direct avec le développement front-end, ce projet m'a offert l'opportunité de maitriser des notions plus avancées telles que l'héritage de classe, les hooks de cycle de vie, le mot-clé 'virtuel', les automates finis etc, ont étendu ma compréhension de la programmation de façon générale.",
            "Wizardman est prévu pour une sortie officielle en 2022.",
          ],
        },
        {
          name: "5e Spellbook",
          thumbnail: imgspellbook,
          link: "5e-spellbook",
          url: "https://5e-spellbook.netlify.app/",
          github: [
            {
              tag: "Front End",
              url: "https://github.com/EggplantHero/spellbook-react",
            },
            {
              tag: "Back End",
              url: "https://github.com/EggplantHero/spellbook-node",
            },
          ],
          category: "MERN Fullstack",
          date: "August 2020",
          description: [
            "5e Spellbook est un outil qui permet à l'utilisateur d'enregister les sorts magiques que connaît son personnage dans le jeu de rôle 'Donjons et Dragons (5ème Edition)'.",
            "L'intention de cette application était de m'exercer en back-end au design d'APIs grâce à Node.js et Express, y compris la création de compte, login, mesures de sécurité telles que les jetons d'authentification, hasher les mots de passe, JWT, etc.",
            "Niveau front-end, cette application m'a aussi permis d'apprendre à chercher les données via REST API, à les afficher sur la page, et enfin les filtrer et trier selon les préférences de l'utilisateur.",
          ],
        },
        {
          name: "SalonClouds+",
          thumbnail: imgsalonclouds,
          link: "salonclouds",
          url: "https://saloncloudsplus.com/portfolio-websites",
          github: [],
          category: "HTML, CSS",
          date: "December 2021",
          description: [
            "SalonClouds+ est une entreprise qui créé des sites web et applications mobiles customisées pour des salons, et ont ainsi leur propre portfolio complet de sites développés.",
            "Malgré avoir été employé en tant qu'agent de support technique, j'ai souvent eu l'occasion de modifier du contenu ou réparer des bugs dans beaucoup de leurs sites web.",
            "Ainsi, beaucoup de code que j'ai écrit est éparpillé parmi tous les sites de leur portfolio, incluant le site de l'entreprise même.",
          ],
        },
      ],
    },
    contact: [
      { name: "Name", data: "Jesse Weber", icon: false },
      { name: "Profile", data: "Développeur Front-End", icon: false },
      {
        name: "Email",
        data: "jessetristanweber@gmail.com",
        icon: <AiOutlineMail />,
      },
      { name: "Phone", data: "07 69 71 42 16", icon: <AiFillPhone /> },
    ],
  },
};

export default content;

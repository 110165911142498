import React from "react";

const Skills = ({ content, id }) => {
  const { title, technologies } = content.skills;
  return (
    <section id={id}>
      <div className="container">
        <h3 className="title" data-aos="zoom-out-up">
          {title}
        </h3>
        <div className="row">
          {technologies.map(({ name, icon, description }) => (
            <div className="col-md-4" key={name}>
              <div data-aos="zoom-in-up" className="box-shadow height-80">
                <span className="circle">{icon}</span>
                <div className="text-center">
                  <h3>{name}</h3>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;

import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiFillGithub, AiOutlineLink } from "react-icons/ai";

const Nav = styled.nav`
  background-color: rgba(0, 0, 0, 0);
`;

const NavItem = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  text-decoration: underline 0.15em rgba(255, 255, 255, 0);
  transition: text-decoration-color 0.3s;
  &:hover {
    color: #fff;
    text-decoration-color: rgba(255, 255, 255, 1);
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
`;

const Jumbo = styled.div`
  height: 30vh;
  width: 100%;
  position: relative;
  background-color: #0078ff;
`;

const Project = ({ content }) => {
  let { projectName } = useParams();

  let { projects } = content.portfolio;

  const data = projects.find((item) => item.link === projectName);
  const { name, thumbnail, description, category, date, url, github } = data
    ? data
    : "";

  return (
    <Fragment>
      <Nav id="navbar" className="navbar navbar-expand-lg fixed-top">
        <NavLink to="/">
          <NavItem className="nav-link">
            <BiArrowBack />
            back
          </NavItem>
        </NavLink>
      </Nav>
      <Jumbo>
        <div className="center-vertical text-white">
          <h1 className="title">{data ? name : "404 not found"}</h1>
        </div>
      </Jumbo>
      {data && (
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-6 col-xl-8">
              <div className="box-shadow">
                <img src={thumbnail} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="mt-0 mt-lg-5">
                {description.map((p, i) => (
                  <p key={i}>{p}</p>
                ))}
                {url && (
                  <a href={url}>
                    <p>
                      <AiOutlineLink />
                      {url}
                    </p>
                  </a>
                )}
                {github.map(({ url, tag }) => (
                  <p key={tag}>
                    <a href={url}>
                      <AiFillGithub />
                      {tag}
                    </a>
                  </p>
                ))}
                <p>
                  <span className="text-primary">{category}</span> / {date}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Project;

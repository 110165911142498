import React, { Fragment } from "react";
import styled from "styled-components";
import BgImg from "../assets/img/hero-bg.jpg";
import TypeWriterEffect from "react-typewriter-effect";

const Jumbo = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  background-image: url(${BgImg});
  background-size: cover;
  background-attachment: fixed;
  background-position: 25% 50%;
`;

const Hero = ({ content, id }) => {
  const text = {
    EN: ["Developer", "Designer", "Artist", "Musician"],
    FR: ["Développeur", "Designer", "Artiste", "Musicien"],
  };

  return (
    <Jumbo id={id}>
      <div className="text-white center-vertical">
        <div data-aos="zoom-out-up">
          <h1 className="hero-title mb-4">Jesse Weber</h1>
          {Object.keys(text).map((lang) => (
            <Fragment key={lang}>
              {content.language === lang && (
                <TypeWriterEffect
                  textStyle={{
                    textAlign: "center",
                  }}
                  cursorColor="#3F3D56"
                  multiText={text[lang]}
                  multiTextDelay={2000}
                  multiTextLoop={true}
                  typeSpeed={50}
                />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </Jumbo>
  );
};

export default Hero;
